import { useState } from 'react';

export default function useSessionStorage(key, initialValue, obj = false) {

    const [value, setValue] = useState(() => {
        let storedValue = sessionStorage.getItem(key);
        if (storedValue) {
            if (obj) {
                try {
                    return JSON.parse(storedValue)
                } catch (error) {
                    return initialValue
                }
            }
            else
                return storedValue
        } else {
            return initialValue
        }
    });

    const updateValue = (newValue) => {
        setValue(newValue);
        let strValue = obj ? JSON.stringify(newValue) : newValue;
        sessionStorage.setItem(key, strValue);
    };

    const removeValue = () => {
        sessionStorage.removeItem(key);
        setValue(initialValue);
    };

    return [value, updateValue, removeValue, initialValue];
}
