import React, { useContext, useState } from 'react'
import { DatePicker, Select, Space } from 'antd';
import Context from '../Utils/Context';
import { useAPI, usePermission } from '../Hooks';
import dayjs from 'dayjs';
import presets from '../Utils/DatePresets';

export default function SearchBox() {
    const { search, setSearch } = useContext(Context)
    const check = usePermission()

    const [searchText, setSearchText] = useState({
        campaign: '',
        email: '',
        keyword: '',
        jobTitle: '',
        website: '',
        department: '',
        company: '',
        country: '',
    })
    const [options, setOptions] = useState({
        campaign: [],
        email: [],
        keyword: [],
        jobTitle: [],
        website: [],
        department: [],
        company: [],
        country: [],
    })

    const changeSearchText = (name, value) => {
        setSearchText({
            ...searchText,
            [name]: value
        })
    }

    const handleChange = (name, value) => {
        changeSearchText(name, '')
        setSearch({
            ...search,
            [name]: value
        })
    }

    const [getOptions] = useAPI({ auth: false, showBar: false })
    const typing = async (name, value) => {
        value = value.replace(/[^ A-Za-z0-9.,-_&()'":;=+*#!~`@]/g, '').replace('  ', ' ').trimStart()
        let single = Septate(name, value)
        if (single) {
            changeSearchText(name, value)
        } else {
            changeSearchText(name, '')
        }
        let data = await getOptions(
          `suggestion?key=${name}&value=${value}`
        );
        if (data) {
            setOptions({
                ...options,
                [name]: data
            })
        }
    }

    const Septate = (name, data) => {
        let Array = data.split(',')
        if (Array.length > 1) {
            let NewArray = [...(search[name])]
            for (let i = 0; i < Array.length; i++) {
                let newString = Array[i].trim()
                if (newString && !NewArray.includes(newString)) {
                    NewArray.push(newString)
                }
            }
            handleChange(name, NewArray)
        }
        return Array.length < 2
    }

    const selectDate = (name, date) => {
        if (date) {
            setSearch({
                ...search,
                [name]: dayjs(date)
            })
        } else {
            setSearch({
                ...search,
                [name]: null
            })
        }
    }

    return (
        <div className='flex flex-col gap-3 flex-grow basis-[600px]'>
            {[
                {
                    title: 'Campaign',
                    name: 'campaign',
                },
                {
                    title: 'Keyword',
                    name: 'keyword',
                },
                {
                    title: 'Website',
                    name: 'website',
                },
                {
                    title: 'Email',
                    name: 'email',
                },
                {
                    title: 'Job Title',
                    name: 'title',
                },
                {
                    title: 'Department',
                    name: 'department',
                },
                {
                    title: 'Company',
                    name: 'company',
                },
                {
                    title: 'Country',
                    name: 'country',
                },
            ].map((value, index) => (
                <Space.Compact key={index} className="bg-gray-100 flex items-center rounded-md">
                    <h5 className=' px-3 py-1 w-24 shrink-0'>{value.title} </h5>
                    <Select
                        value={search[value.name]}
                        className='w-full h-full !rounded-none max-w-[900px]'
                        mode="tags"
                        onChange={(input) => handleChange(value.name, input)}
                        options={options[value.name]}
                        onSearch={(e) => typing(value.name, e)}
                        searchValue={searchText[value.name]}
                        allowClear
                        size='large'
                    />
                </Space.Compact>
            ))}
            {check('ApplyDateRange') && (
                <Space.Compact className="bg-gray-100 items-center rounded-lg flex">
                    <h5 className=' px-3 shrink-0'>Date Range</h5>
                    <DatePicker
                        value={search.fromDate && dayjs(search.fromDate)}
                        size='large'
                        className='w-full'
                        presets={presets}
                        placeholder='from'
                        onChange={(e) => selectDate('fromDate', e)}
                        disabledDate={(current) => current && current > dayjs().endOf('day')}
                    />
                    <DatePicker
                        value={search.toDate && dayjs(search.toDate)}
                        size='large'
                        className='w-full'
                        presets={presets}
                        placeholder='to'
                        onChange={(e) => selectDate('toDate', e)}
                        disabledDate={(current) => current && current > dayjs().endOf('day')}
                    />
                </Space.Compact>
            )}
        </div >
    )
}
