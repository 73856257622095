import { Button, Space } from 'antd'
import React, { useContext } from 'react'
import Context from '../Utils/Context'
import { usePermission } from '../Hooks'

export default function EmailTabs({ loading }) {
    const { search, setSearch } = useContext(Context)
    const check = usePermission()
    const Menu = [
        {
            name: 'Normal',
            key: 'normal',
            permission: 'allowViewNormalEmail'
        },
        {
            name: 'Unsubscribe',
            key: 'unsubscribe',
            permission: 'allowUnsubscribeEmail'
        },
        {
            name: 'Soft Bounce',
            key: 'soft',
            permission: 'allowSoftBounceEmail'
        },
        {
            name: 'Hard Bounce',
            key: 'hard',
            permission: 'allowHardBounceEmail'
        },
        {
            name: 'Leads',
            key: 'leads',
            permission: 'allowLeadsEmail'
        },
        {
            name: 'Sales',
            key: 'sales',
            permission: 'allowSalesEmail'
        },
    ]

    const switchToggle = (key) => {
        setSearch({
            ...search,
            [key]: !search[key]
        })
    }

    return (
        <>
            <div className='p-4 m-auto overflow-auto'>
                <Space.Compact className=''>
                    {Menu.map(e => check(e.permission) ? (
                        <Button
                            key={e.key}
                            disabled={loading}
                            type={search[e.key] ? 'primary' : 'default'}
                            hidden={e.hidden}
                            size='large'
                            onClick={() => {
                                if (e.key === 'normal') {
                                    if (check('NormalClickable'))
                                        switchToggle(e.key)
                                } else
                                    switchToggle(e.key)
                            }}
                            className='mx-[1px]'
                        >
                            {e.name}
                        </Button>
                    ) : <></>)}
                </Space.Compact>
            </div>
        </>
    )
}
