import { useContext, useState } from 'react';
import Context from '../Utils/Context';
import { useNavigate } from 'react-router-dom';
const CustomError = (code) => [409, 401, 406, 401, 404, 422].includes(code)

export default function useFileAPI() {
    const { token, Message, SERVER } = useContext(Context)
    const BASEURL = SERVER + '/api'
    const navigate = useNavigate()
    const [controller, setController] = useState(null);
    const [progress, setProgress] = useState(0);

    const request = async (file) => {
        try {
            if (!token) {
                navigate('/login')
                throw new Error("Please Login")
            }

            const formData = new FormData();
            formData.append('file', file);

            const abortController = new AbortController();
            setController(abortController);

            const response = await fetch((BASEURL + '/upload-file'), {
                method: 'POST',
                body: formData,
                signal: abortController.signal,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentage)
                },
            });

            if (response.status === 200) {
                return await response.json();
            } else if (CustomError(response.status)) {
                let data = await response.json()
                throw new Error(data.message)
            } else {
                throw new Error("Server Error")
            }
        } catch (error) {
            Message.open({
                key: "server-error",
                type: 'error',
                content: error.message,
                duration: 3,
            });
            console.error(error.message)
            return null
        } finally {
            setProgress(100)
        }
    }

    const handleCancel = () => {
        if (controller) {
            controller.abort();
            setController(null);
            setProgress(0);
            return true
        }
        return false
    };

    return [request, progress, handleCancel];
}