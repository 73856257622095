import React, { useContext, useEffect, useState } from 'react';
import { Badge, Pagination, Table } from 'antd';
import { ActionBox, EmailTabs, SearchBox } from '../Components';
import Context from '../Utils/Context';
import { useAPI, usePermission, useSessionStorage } from '../Hooks';
import dayjs from 'dayjs';

export default function Download() {
    const { search, setSearch } = useContext(Context)
    const { sorter } = search
    const [pageSize, setPageSize] = useSessionStorage('pageSize', 10)
    const [pageNo, setPageNo] = useState(1)
    const [total, setTotal] = useState(0)
    const [Data, setData] = useState([])
    const check = usePermission()

    const [request, loading] = useAPI({
        method: 'POST',
        route: `emails?limit=${pageSize}&page=${pageNo}`,
        body: {
            ...search,
            toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
            fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
            normal: (check('allowViewNormalEmail') ? search.normal : false),
        },
    })

    const getData = async () => {
        let data = await request()
        if (data) {
            setTotal(data.total)
            setData(data.data)
        }
    }

    useEffect(() => {
        getData({ pageNo, pageSize, search })
        // eslint-disable-next-line
    }, [pageNo, pageSize, search])

    return (
        <>
            <div className="select-text p-4 flex flex-wrap gap-4 ">
                <SearchBox />
                <ActionBox total={total} />
            </div>
            <EmailTabs loading={loading} />
            <div className="w-full overflow-x-auto select-text">
                <Table
                    onChange={(p, f, { field, order }) => {
                        setSearch({
                            ...search,
                            sorter: { field, order }
                        })
                    }}
                    dataSource={Data}
                    pagination={false}
                    loading={loading}
                    columns={[
                        {
                            title: 'First Name',
                            dataIndex: 'firstName',
                            key: 'firstName',
                            sortOrder: (sorter.field === 'firstName') ? sorter.order : null,
                            sorter: true
                        },
                        {
                            title: 'Department',
                            dataIndex: 'department',
                            key: 'Department',
                            align: 'center',
                            sortOrder: (sorter.field === 'department') ? sorter.order : null,
                            sorter: true
                        },
                        {
                            title: 'Website',
                            dataIndex: 'website',
                            key: 'website',
                            align: 'center',
                            sortOrder: (sorter.field === 'website') ? sorter.order : null,
                            sorter: true
                        },
                        {
                            title: 'Campaign',
                            dataIndex: 'campaign',
                            key: 'campaign',
                            align: 'center',
                            sortOrder: (sorter.field === 'campaign') ? sorter.order : null,
                            sorter: true
                        },
                        {
                            title: 'Country',
                            dataIndex: 'country',
                            key: 'country',
                            align: 'center',
                            sortOrder: (sorter.field === 'country') ? sorter.order : null,
                            sorter: true
                        },
                        {
                            title: 'Updated At',
                            key: 'update',
                            align: 'center',
                            render: (_, record) => (<div className='min-w-[120px] block'>{dayjs(record.update).format('DD MMM YYYY - hh:mm A')}</div>),
                        },
                        {
                            title: 'Subscribe',
                            key: 'Subscribe',
                            align: 'center',
                            render: (_, record) => <>
                                {record.unsubscribe ? (
                                    <Badge count={'unsubscribe'} color='red' />
                                ) : (
                                    <Badge count={'subscribe'} color='blue' />
                                )}
                            </>
                        },
                        {
                            title: 'Bounce',
                            key: 'Bounce',
                            align: 'center',
                            render: (_, record) => <>
                                {record.hard ? (
                                    <Badge count={'hard'} color='red' />
                                ) : record.soft ? (
                                    <Badge count={'soft'} color='orange' />
                                ) : (
                                    <Badge count={'active'} color='blue' />
                                )}
                            </>
                        },
                        {
                            title: 'Status',
                            key: 'status',
                            align: 'center',
                            render: (_, record) => <>
                                {record.leads ? (
                                    <Badge count={'leads'} color='green' />
                                ) : record.sales ? (
                                    <Badge count={'sales'} color='gold' />
                                ) : (
                                    <Badge count={'normal'} color='blue' />
                                )}
                            </>
                        },
                    ]}
                />
            </div>
            <div className="my-5 w-full flex justify-end px-4">
                <Pagination
                    responsive
                    current={pageNo}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, pageSize) => { setPageNo(page); setPageSize(pageSize) }}
                />
            </div>
        </>
    )
}