import React, { useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import Context from './Utils/Context';
import { Layout } from './Components';
import { Error404, Dashboard, Login, User, Download, Emails, UpdateUser, BlockDomains, AllowIP, CompanyWebsite, Keyword, Countries, CoolingPeriod, UserLog, Campaign } from './Pages';
import { usePermission } from './Hooks';
import DevLicense from './Utils/DevLicense';

const App = () => {
  const { progress, setProgress, MessageContext } = useContext(Context)
  const check = usePermission()

  return (
    <>
      {MessageContext}
      <LoadingBar
        color='#dc4955'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <DevLicense />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Layout />} >
          <Route index element={<Dashboard />} />
          {check('viewEmail') && <Route path='emails' element={<Emails />} />}
          {check('viewWebsite') && <Route path='website' element={<CompanyWebsite />} />}
          {check('viewKeyword') && <Route path='keyword' element={<Keyword />} />}
          {check('viewDownload') && <Route path='downloads' element={<Download />} />}
          {check('viewCountries') && <Route path='countries' element={<Countries />} />}
          {check('viewCampaigns') && <Route path='campaigns' element={<Campaign />} />}

          {check('viewBlockDomains') && <Route path='block-domains' element={<BlockDomains />} />}

          {check('viewIP') && <Route path='allow-ip' element={<AllowIP />} />}

          {check('viewUser') && <Route path='users' element={<User />} />}
          {check('viewUserLog') && <Route path='user-log' element={<UserLog />} />}
          {check('createUser') && <Route path='user' element={<UpdateUser />} />}
          {check('editUser') && <Route path='user/:username' element={<UpdateUser />} />}

          {check('viewCoolingPeriodEmail') && <Route path='cooling-period' element={<CoolingPeriod />} />}
          <Route path='*' element={<Error404 />} />
        </Route>
      </Routes >
    </>
  )
};

export default App;
