import dayjs from "dayjs"

const presets = [
    {
        label: 'last Day',
        value: dayjs().add(-1, 'd'),
    },
    {
        label: 'last 7 Days',
        value: dayjs().add(-7, 'd'),
    },
    {
        label: 'last 14 Days',
        value: dayjs().add(-14, 'd'),
    },
    {
        label: 'last 21 Days',
        value: dayjs().add(-21, 'd'),
    },
    {
        label: 'last 1 Month',
        value: dayjs().add(-1, 'month'),
    },
    {
        label: 'last 2 Months',
        value: dayjs().add(-2, 'month'),
    }
]

export default presets