import { useContext } from 'react'
import Context from '../Utils/Context'

export default function usePermission() {
    const { user } = useContext(Context)
    const AllowUse = (key) => {
        return (user.permission) ? (user.permission).includes(key) : false
    }
    return AllowUse
}
