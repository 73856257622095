import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Statistic, Spin, Button } from "antd";
import React, { useContext, useState } from "react";
import { BsDatabaseDown } from "react-icons/bs";
import Context from "../Utils/Context";
import useStream from "../Hooks/useStream";
import handleDownload from "../Utils/handleDownload";

export default function DownloadAll() {
  const { SERVER, Message } = useContext(Context);
  const [modalOpen, setModalOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { request, data, cancelNew } = useStream((action) => {
    setModalOpen(false);
    Message.open({
      key: "UploadAndAction",
      type: action,
      content:
        "Create file" +
        (action === "success"
          ? " Successfully"
          : action === "warning"
          ? " Cancel"
          : " Failed"),
    });
    if (action === "success") {
      handleDownload("all-database.zip", SERVER);
    }
  });

  const action = async () => {
    setShowConfirm(false);
    setModalOpen(true);
    request("all-download");
  };

  return (
    <>
      <Modal
        title={
          <div className="flex items-center text-xl">
            <BsDatabaseDown className="text-[#262626] mr-3" />
            Download All Database
          </div>
        }
        open={modalOpen}
        centered
        closeIcon={false}
        footer={false}
      >
        <div className="flex items-center gap-2 flex-col mt-6">
          <div className="flex items-center gap-3">
            <Spin />
            <Statistic value={data} suffix="%" />
          </div>
          <h1 className="text-gray-600">Create CSV File</h1>
          <Button type="primary" danger key="Cancel" onClick={cancelNew}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        title={
          <div className="flex items-center text-xl">
            <ExclamationCircleFilled className="text-orange-600 mr-3" />
            Are you sure ?
          </div>
        }
        open={showConfirm}
        centered
        okText="Download"
        onOk={action}
        onCancel={() => setShowConfirm(false)}
      />
      <div
        onClick={() => setShowConfirm(true)}
        className="bg-gray-100/50 hover:bg-gray-100 cursor-pointer !rounded-lg overflow-hidden flex flex-col items-center shadow-md "
      >
        <div className={`bg-[#262626]/20 text-[#262626] p-3 rounded-xl m-5`}>
          <BsDatabaseDown className="text-5xl" />
        </div>
        <h3 className="text-xl text-gray-700 px-5">Download All Database</h3>
        <div className={`bg-[#262626] h-2 w-full mt-2`} />
      </div>
    </>
  );
}
