import React, { useContext, useEffect, useState } from 'react';
import { Button, DatePicker, InputNumber, Input, Pagination, Space, Table } from 'antd';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAPI, useSessionStorage } from '../Hooks';
import DatePresets from '../Utils/DatePresets';
import Context from '../Utils/Context';
dayjs.extend(relativeTime)

export default function Campaign() {
  const { SERVER, creditRefresh, Message, initialSearch } = useContext(Context)
  const [pageSize, setPageSize] = useSessionStorage('pageSize', 10)
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [Data, setData] = useState([])
  const [search, setSearch] = useState({
    fromDate: null,
    toDate: null,
    campaign: '',
    count: null,
    sorter: {
      field: "",
      order: ""
    }
  })

  const [request, loading] = useAPI({
    route: `campaigns?limit=${pageSize}&page=${pageNo}`,
    method: 'POST',
    body: {
      ...search,
      toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
      fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
    },
  })

  const getData = async () => {
    let data = await request()
    if (data) {
      setTotal(data.total)
      setData(data.data)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [pageNo, pageSize, search])


  const selectDate = (name, date) => {
    if (date) {
      setSearch({
        ...search,
        [name]: dayjs(date)
      })
    } else {
      setSearch({
        ...search,
        [name]: null
      })
    }
  }

  const [fileRequest, fileCreating] = useAPI({ method: 'POST' })

  const DownloadFile = async (name) => {
    Message.open({
      key: "Download",
      type: 'loading',
      content: 'creating file...',
    });

    const res = await fileRequest('new-download', {
      ...initialSearch,
      campaign: [name],
      toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
      fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
    })

    if (res) {
      if (res.name !== '') {
        handleDownload(res.name)
        getData()
        Message.open({
          key: "Download",
          type: 'success',
          content: 'file create successfully',
          duration: 3,
        });
      } else {
        Message.open({
          key: "Download",
          type: 'error',
          content: 'Please increase your download credit',
          duration: 3,
        });
      }
      creditRefresh()
    }
  }

  const handleDownload = (name) => {
    const link = document.createElement('a');
    link.href = `${SERVER}/file/download/${name}`;
    link.download = name;
    link.click();
  };

  return <>
    <div className="p-4 flex flex-col gap-2">
      <div className="flex  gap-2">
        <Space.Compact className="bg-gray-100 flex items-center flex-grow rounded-md">
          <h5 className=' px-3 py-1 w-24 shrink-0'>Campaign </h5>
          <Input
            placeholder='Search'
            value={search.campaign}
            onChange={({ target }) => {
              setSearch({
                ...search,
                campaign: target.value
              })
            }}
            size='large'
            allowClear
          />
        </Space.Compact>
        <Space.Compact className="bg-gray-100 flex items-center w-80 rounded-md">
          <h5 className=' px-3 py-1 shrink-0'>Count </h5>
          <InputNumber
            className='w-full'
            value={search.count}
            min="0"
            max="10000"
            step="1"
            size='large'
            onChange={(count) => setSearch({ ...search, count })}
          />
        </Space.Compact>
      </div>
      <Space.Compact className="bg-gray-100 items-center rounded-lg flex">
        <h5 className=' px-3 shrink-0'>Date Range</h5>
        <DatePicker
          value={search.fromDate && dayjs(search.fromDate)}
          size='large'
          className='w-full'
          presets={DatePresets}
          placeholder='from'
          onChange={(e) => selectDate('fromDate', e)}
          disabledDate={(current) => current && current > dayjs().endOf('day')}
        />
        <DatePicker
          value={search.toDate && dayjs(search.toDate)}
          size='large'
          className='w-full'
          presets={DatePresets}
          placeholder='to'
          onChange={(e) => selectDate('toDate', e)}
          disabledDate={(current) => current && current > dayjs().endOf('day')}
        />
      </Space.Compact>
    </div>
    <Table
      loading={loading}
      columns={[
        {
          title: 'Campaign',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          sortOrder: (search.sorter.field === 'name') ? search.sorter.order : null,
          sorter: true
        },
        {
          title: 'Mails',
          align: 'center',
          key: 'mails',
          render: (_, record) => Intl.NumberFormat().format(record.mails)
        },
        {
          title: 'Download Count',
          dataIndex: 'count',
          key: 'count',
          align: 'center',
          sortOrder: (search.sorter.field === 'count') ? search.sorter.order : null,
          sorter: true,
        },
        {
          title: 'Last Download Date',
          dataIndex: 'last',
          key: 'last',
          align: 'center',
          render: (_, record) => record.last ? dayjs(record.last).format('DD MMM YYYY - hh:mm A') : 'Not Download',
          sortOrder: (search.sorter.field === 'last') ? search.sorter.order : null,
          sorter: true
        },
        {
          title: 'Last Download',
          key: 'last',
          align: 'center',
          render: (_, record) => record.last ? dayjs().to(dayjs(record.last)) : '😒',
        },
        {
          title: 'Action',
          key: 'action',
          align: 'center',
          render: (_, record) => (
            <div className='flex justify-center gap-2'>
              {<Button
                disabled={fileCreating}
                type='primary'
                className='inline-flex gap-2 items-center'
                onClick={() => DownloadFile(record.name)}>
                <IoCloudDownloadOutline />
              </Button>}
            </div>
          )
        }
      ]}
      sticky
      dataSource={Data}
      pagination={false}
      onChange={(p, f, { field, order }) => {
        setSearch({
          ...search,
          sorter: { field, order }
        })
      }}
    />
    <div className="my-5 w-full flex justify-end px-4">
      <Pagination
        responsive
        current={pageNo}
        pageSize={pageSize}
        total={total}
        onChange={(page, pageSize) => { setPageNo(page); setPageSize(pageSize) }}
      />
    </div>
  </>
}