import React, { createContext, useState } from 'react'
import useSessionStorage from '../Hooks/useSessionStorage'
import { useLocalStorage } from '../Hooks'
import { message } from 'antd'

const Context = createContext()
const SERVER = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASEURL_DEV : window.location.origin;
export const ContextProvider = ({ children }) => {
    const [refreshed, setRefresh] = useState(0)
    const Refresh = () => {
        setRefresh(refreshed + 1)
    }
    const [creditRefreshed, setCreditRefreshed] = useState(0)
    const creditRefresh = () => {
        setCreditRefreshed(creditRefreshed + 1)
    }

    const [Message, MessageContext] = message.useMessage();

    const [search, setSearch, RemoveSearch, initialSearch] = useSessionStorage('search', {
        campaign: [],
        keyword: [],
        website: [],
        email: [],
        title: [],
        department: [],
        company: [],
        country: [],
        toDate: null,
        fromDate: null,
        sorter: {
            field: "",
            order: ""
        },
        normal: true,
        unsubscribe: false,
        soft: false,
        hard: false,
        leads: false,
        sales: false,
    }, true)

    const [user, setUser, removeUser] = useSessionStorage('user', {
        name: "",
        username: "",
        permission: []
    }, true)

    const [progress, setProgress] = useState(0)
    const [token, seToken, removeToken] = useLocalStorage('token', '')

    const value = {
        SERVER,
        Message, MessageContext,
        creditRefreshed, creditRefresh,
        refreshed, Refresh,
        progress, setProgress,
        token, seToken, removeToken,
        user, setUser, removeUser,
        search, setSearch, RemoveSearch, initialSearch
    }
    return <Context.Provider value={value} > {children}</Context.Provider >
}
export default Context;