import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { useAPI } from '../Hooks';

export default function Countries() {
  const [Data, setData] = useState([])

  const [loadDomain, loading] = useAPI({})
  const getData = async () => {
    const document = await loadDomain(`countries`)
    if (document) {
      setData(document)
    }
  }
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Table
        loading={loading}
        columns={[
          {
            title: 'Name',
            dataIndex: '_id',
            key: '_id',
          },
          {
            title: 'Email Count',
            dataIndex: 'emailCount',
            key: 'emailCount',
            align: 'center',
          }
        ]}
        sticky
        dataSource={Data}
        pagination={false}
      />
    </>
  )
}
