import { Button, Popconfirm } from 'antd'
import React, { useContext } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import { useAPI } from '../Hooks'
import Context from '../Utils/Context'

export default function DeleteBox({ disabled }) {
    const { search, RemoveSearch, Message } = useContext(Context)

    const [request, loading] = useAPI({
        body: search,
        method: 'POST',
        route: 'emails/delete'
    })

    const Delete = async () => {
        Message.open({
            key: "delete",
            type: 'loading',
            content: 'Deleting...',
        });
        const res = await request()
        if (res) {
            RemoveSearch()
            Message.open({
                key: "delete",
                type: 'success',
                content: 'Delete successfully',
                duration: 2,
            });
        } else {
            Message.open({
                key: "delete",
                type: 'error',
                content: 'Can\'t Delete',
                duration: 2,
            });
        }
    }
    return (
        <>
            <Popconfirm title="Sure to delete?" onConfirm={Delete}>
                <Button loading={loading} disabled={disabled} size='large' danger type='primary' className='flex gap-2 w-48 justify-center items-center'>
                    <MdDeleteOutline />
                    Delete
                </Button>
            </Popconfirm>
        </>

    )
}
