import { Button, Modal } from 'antd'
import React from 'react'
import { BsFileEarmarkArrowDown } from 'react-icons/bs'
import { usePermission } from '../Hooks';

export default function SampleDownload() {
    const [modal, contextHolder] = Modal.useModal();
    const check = usePermission()

    const Download = ({ name, data }) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8,' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = name;
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
    };

    const Data = [
        {
            name: 'Add New Emails',
            permission: 'uploadNewEmail',
            data: `${check('downloadEmailWithCampaign') ? 'Campaign,' : ''}First Name,Last Name,Title,Department,Company,Website,Email,Country,Phone,Keyword,Industry,Linkedin\n`,
        },
        {
            name: 'Unsubscribe Emails',
            permission: 'allowUnsubscribeEmail',
            data: 'Email\n',
        },
        {
            name: 'Soft Bounce Emails',
            permission: 'allowSoftBounceEmail',
            data: 'Email\n',
        },
        {
            name: 'Hard Bounce Emails',
            permission: 'allowHardBounceEmail',
            data: 'Email\n',
        },
        {
            name: 'Leads Emails',
            permission: 'allowLeadsEmail',
            data: 'Email\n',
        },
        {
            name: 'Sales Emails',
            permission: 'allowSalesEmail',
            data: 'Email\n',
        },
    ]

    const config = {
        title: 'Sample Download of',
        icon: <BsFileEarmarkArrowDown className='text-2xl text-[#3f6212] mr-2' />,
        content: (
            <div className='flex flex-col items-center gap-2'>
                {Data.map((e) => ((check(e.permission)) ? (
                    <Button
                        key={e.name}
                        onClick={() => {
                            Download({
                                data: e.data,
                                name: e.name
                            })
                        }}
                        className='flex gap-2 items-center w-48 justify-center'>
                        {e.name}
                    </Button>
                ) : <></>))}
            </div>
        ),
    }

    return (
        <>
            {contextHolder}
            <div
                onClick={async () => {
                    modal.info(config);
                }}
                className='bg-gray-100/50 hover:bg-gray-100 cursor-pointer !rounded-lg overflow-hidden flex flex-col items-center shadow-md '>
                <div className={`bg-[#3f6212]/20 text-[#3f6212] p-3 rounded-xl m-5`}>
                    <BsFileEarmarkArrowDown className='text-5xl' />
                </div>
                <h3 className='text-xl text-gray-700 px-5'>Sample Download</h3>
                <div className={`bg-[#3f6212] h-2 w-full mt-2`} />
            </div>
        </>
    )
}
