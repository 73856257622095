import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Checkbox, Input, InputNumber, Select, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Permission from "../Config/Permission";
import { useContext, useEffect, useState } from "react";
import { useAPI } from "../Hooks";
import Context from "../Utils/Context";

export default function UpdateUser() {
  const { creditRefresh, Message } = useContext(Context);
  const navigate = useNavigate();
  const { username } = useParams();
  const [newUser, setNewUser] = useState(true);
  const [userID, setUserID] = useState("");
  const [userPermission, setUserPermission] = useState([]);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userUsername, setUserUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCredit, setUserCredit] = useState(0);
  const [userAddDefault, setAddDefault] = useState(false);
  const [userDefaultEmail, setDefaultEmail] = useState([]);
  const [userDefaultName, setDefaultName] = useState([]);

  const [getUserRequest, UpdatedUserLoading] = useAPI({});
  useEffect(() => {
    if (username) {
      setNewUser(false);
      getUserRequest("user/" + username).then((data) => {
        setUserName(data.name);
        setUserUsername(data.username);
        setUserEmail(data.email);
        setUserID(data._id);
        setUserCredit(data.credit);
        setUserPermission(data.permission);
        setAddDefault(data.addDefault);
        setDefaultEmail(data.defaultEmail);
        setDefaultName(data.defaultName);
      });
    }
    // eslint-disable-next-line
  }, []);

  const changePermission = ({ target }) => {
    const { value } = target;
    if (userPermission.includes(value)) {
      setUserPermission(userPermission.filter((item) => item !== value));
    } else {
      setUserPermission([...userPermission, value]);
    }
  };

  const [request, Loading] = useAPI({
    route: newUser ? "user" : "user/" + userID,
    method: newUser ? "POST" : "PUT",
    body: {
      name: userName,
      username: userUsername,
      email: userEmail,
      password: userPassword,
      credit: userCredit,
      permission: userPermission,
      defaultEmail: userDefaultEmail,
      addDefault: userAddDefault,
      defaultName: userDefaultName,
    },
  });

  const saveUser = async () => {
    const response = await request();
    if (response) {
      Message.open({
        key: "User",
        type: "success",
        content: `User is successfully ${newUser ? "created" : "saved"}`,
      });
      navigate("/users");
      creditRefresh();
    }
  };

  const changeUsername = ({ target }) => {
    let { value } = target;
    setUserUsername(value.toLowerCase().replace(/[^a-zA-Z0-9.]/g, ""));
  };
  return (
    <div className="p-4 flex flex-col gap-3 m-auto max-w-4xl">
      <Input
        addonBefore={
          <label htmlFor="NameBox" className="w-20 block text-start">
            Name
          </label>
        }
        id="NameBox"
        name="name"
        onChange={({ target }) => setUserName(target.value)}
        value={userName}
        size="large"
        disabled={Loading || UpdatedUserLoading}
      />
      <Input
        addonBefore={
          <label htmlFor="UsernameBox" className="w-20 block text-start">
            Username
          </label>
        }
        id="UsernameBox"
        name="username"
        value={userUsername}
        onChange={changeUsername}
        size="large"
        disabled={Loading || UpdatedUserLoading}
      />
      <Input
        addonBefore={
          <label htmlFor="EmailBox" className="w-20 block text-start">
            Email
          </label>
        }
        id="EmailBox"
        name="email"
        value={userEmail}
        onChange={({ target }) => setUserEmail(target.value)}
        size="large"
        disabled={Loading || UpdatedUserLoading}
      />
      <InputNumber
        addonBefore={
          <label htmlFor="creditBox" className="w-20 block text-start">
            Credit
          </label>
        }
        className="w-full"
        value={userCredit}
        min="0"
        size="large"
        disabled={Loading || UpdatedUserLoading}
        onChange={(count) => setUserCredit(count)}
        controls={false}
        changeOnWheel={false}
        keyboard={false}
      />
      <Input.Password
        addonBefore={
          <label htmlFor="PasswordBox" className="w-20 block text-start">
            Password
          </label>
        }
        id="PasswordBox"
        name="password"
        value={userPassword}
        onChange={({ target }) => setUserPassword(target.value)}
        size="large"
        placeholder={!newUser && "new password"}
        disabled={Loading || UpdatedUserLoading}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <Space.Compact className="bg-[#fafafa] flex items-center rounded-md">
        <h5 className=" px-3 py-1 w-28 shrink-0">Add Default </h5>
        <Select
          value={userAddDefault}
          className="w-full"
          onChange={(value) => setAddDefault(value)}
          size="large"
          disabled={Loading || UpdatedUserLoading}
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
        />
      </Space.Compact>
      {userAddDefault ? (
        <>
          <Space.Compact className="bg-gray-100 flex items-center rounded-md">
            <h5 className=" px-3 py-1 w-28 shrink-0">Add Emails </h5>
            <Select
              value={userDefaultEmail}
              className="w-full"
              mode="tags"
              onChange={(value) => {
                setDefaultEmail(value);
              }}
              allowClear
              size="large"
            />
          </Space.Compact>
          <Space.Compact className="bg-gray-100 flex items-center rounded-md">
            <h5 className=" px-3 py-1 w-28 shrink-0">Add Name </h5>
            <Select
              value={userDefaultName}
              className="w-full"
              mode="tags"
              onChange={(value) => {
                setDefaultName(value);
              }}
              allowClear
              size="large"
            />
          </Space.Compact>
        </>
      ) : (
        <></>
      )}
      <div className="flex flex-wrap gap-3 p-3 bg-[#00000005] rounded-lg border-[1px] border-[#d9d9d9]">
        <h4 className="block text-base text-start w-full mb-2">Permission</h4>
        {Permission.map((p) => (
          <Checkbox
            key={p.key}
            value={p.key}
            disabled={Loading || UpdatedUserLoading}
            checked={userPermission.includes(p.key)}
            onChange={changePermission}
          >
            {p.title}
          </Checkbox>
        ))}
      </div>
      <div className="flex justify-end gap-3 ">
        <Button
          type="primary"
          size="large"
          className="w-24"
          disabled={Loading}
          onClick={saveUser}
        >
          Save
        </Button>
        <Button
          danger
          onClick={() => navigate("/users")}
          size="large"
          className="w-24"
          disabled={Loading}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
