import React, { useContext, useEffect, useState } from 'react';
import { Button, FloatButton, Popconfirm, Table } from 'antd';
import { AiOutlineDelete } from "react-icons/ai";
import { MdModeEditOutline, MdPersonAdd } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useAPI, usePermission } from '../Hooks';
import Context from '../Utils/Context';

export default function User() {
    const navigate = useNavigate();
    const check = usePermission()
    const { user, Message } = useContext(Context)
    const [Data, setData] = useState([])
    const [request, Loading] = useAPI({
        route: 'user'
    })

    const [DeleteRequest] = useAPI({
        method: 'DELETE',
    })

    const DeleteUser = async (username) => {
        let response = await DeleteRequest('user/' + username)
        if (response) {
            Message.open({
                key: "user",
                type: 'success',
                content: `${username} delete successfully`,
            });
            getData()
        }
    }

    const getData = async () => {
        let data = await request()
        if (data) {
            setData(data)
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {check('createUser') && <FloatButton
                type='primary'
                tooltip='Add New User'
                icon={<MdPersonAdd />}
                onClick={() => navigate('/user')}
            />}
            <Table
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Username',
                        dataIndex: 'username',
                        key: 'username',
                        align: 'center',
                    },
                    {
                        title: 'Permission',
                        key: 'permission',
                        align: 'center',
                        render: (_, record) => record.permission.length,
                    },
                    {
                        title: 'Credit',
                        key: 'credit',
                        align: 'center',
                        render: (_, record) => (Intl.NumberFormat().format(record?.credit || 0)),
                    },
                    {
                        title: 'Action',
                        key: 'action',
                        align: 'center',
                        render: (_, record) => (
                            <div className='flex justify-center gap-2'>
                                {check('editUser') && <Button type='primary'>
                                    <Link to={`/user/${record.username}`} className='flex gap-2 items-center'>
                                        <MdModeEditOutline />
                                        Edit
                                    </Link>
                                </Button>}
                                {check('deleteUser') && <Popconfirm
                                    title="Sure to delete?"
                                    onConfirm={() => DeleteUser(record.username)}
                                >
                                    <Button
                                        danger
                                        disabled={record.username === user.username}
                                        className='flex gap-2 items-center'>
                                        <AiOutlineDelete />
                                        Delete
                                    </Button>
                                </Popconfirm>}
                            </div>),
                    },
                ]}
                loading={Loading}
                pagination={false}
                sticky
                dataSource={Data} />
        </>

    )
}