import React, { useContext, useLayoutEffect } from 'react'
import { useAPI } from '../Hooks'
import Context from './Context'
import { useNavigate } from 'react-router-dom'

export default function UserInfo() {
    const { setUser } = useContext(Context)
    const navigate = useNavigate()
    const [request] = useAPI({
        route: '/'
    })

    const getData = async () => {
        let data = await request()
        if (data) {
            setUser(data)
        } else {
            navigate('/login')
        }
        document.querySelector(".page-loader").classList.add("fade-out")
        setTimeout(function () {
            document.querySelector(".page-loader").style.display = "none";
        }, 600);
    }

    useLayoutEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])
    return <></>
}
