import React, { useContext, useLayoutEffect, useState } from 'react'
import { IoKeyOutline } from "react-icons/io5";
import { CiLogin } from "react-icons/ci";
import { Button, Form, Input, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import { useAPI } from '../Hooks';
import { useNavigate } from 'react-router-dom';
import Context from '../Utils/Context';
import { ForgotPassword } from '../Components';
const { Title } = Typography;

export default function Login() {
  const { seToken, Message } = useContext(Context)
  const [UserData, setUserData] = useState({
    username: '',
    password: '',
  })

  const [OpenForgotPassword, setOpenForgotPassword] = useState('')

  const changeData = ({ target }) => {
    let { name, value } = target
    setUserData({
      ...UserData,
      [name]: value
    })
  }

  const navigate = useNavigate()
  const [request, Loading] = useAPI({
    method: 'POST',
    route: 'login',
    body: UserData,
    auth: false
  })

  const submit = async (e) => {
    e.preventDefault()
    let response = await request()
    if (response) {
      seToken(response.token);
      navigate('/')
      Message.open({
        key: "Login",
        type: 'success',
        content: 'successfully Login',
        duration: 3,
      });
    }
  }

  useLayoutEffect(() => {
    document.querySelector(".page-loader").classList.add("fade-out")
    setTimeout(function () {
      document.querySelector(".page-loader").style.display = "none";
    }, 600);    
    // eslint-disable-next-line
  }, [])

  return (<>
    <div className="absolute top-0 left-0 w-full h-full bg-cover z-0" style={{ backgroundImage: 'url("/img/LoginBG.png")' }}></div>
    <main className='w-full min-h-screen bg-black/60 relative flex justify-center items-center z-10'>
      <div className='bg-white p-6 rounded-xl w-full max-w-lg mx-2'>
        <div className="w-full flex justify-center mb-4">
          <Title level={2}>Login</Title>
        </div>
        <ForgotPassword OpenForgotPassword={OpenForgotPassword} setOpenForgotPassword={setOpenForgotPassword} />
        <Form className="flex flex-col gap-5 mb-3" autoComplete="on">
          <Input
            size="large"
            placeholder="username"
            id="username"
            name="username"
            value={UserData.username}
            onChange={changeData}
            disabled={Loading}
            autoFocus={true}
            autoComplete='username'
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
          <Input.Password
            size="large"
            placeholder="password"
            id="password"
            name="password"
            value={UserData.password}
            onChange={changeData}
            disabled={Loading}
            autoComplete='current-password'
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            prefix={<IoKeyOutline className="site-form-item-icon" />}
          />
          <Form.Item colon={false} className='mb-0'>
            <Button
              htmlType="submit"
              type="primary"
              className='bg-[#1677ff] w-full justify-center flex items-center'
              icon={<CiLogin />}
              size='large'
              onClick={submit}
              disabled={Loading}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </main>
  </>);
}
