import React, { useContext, useLayoutEffect, useState } from 'react'
import { useAPI } from '../Hooks'
import Context from '../Utils/Context'
import { Spin } from 'antd';

export default function UserCredit() {
    const { creditRefreshed } = useContext(Context)
    const [userCredit, setUserCredit] = useState(0)
    const [request, loading] = useAPI({
        route: '/credit'
    })

    const getData = async () => {
        let data = await request()
        if (data) {
            setUserCredit(data.credit)
        }
    }

    useLayoutEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [creditRefreshed])

    return <h3 className=''>Credit : {loading ? < Spin /> : Intl.NumberFormat().format(userCredit)}</h3>
}
