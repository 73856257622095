import React, { useContext, useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { HiOutlineMail } from 'react-icons/hi'
import { IoAnalytics } from 'react-icons/io5'
import { useNavigate } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { ClearAll, DownloadAll, SampleDownload, UploadAndAction } from '../Components';
import { useAPI, usePermission } from '../Hooks';
import { Spin } from 'antd';
import Context from '../Utils/Context';
import { MdAdd, MdMarkEmailRead, MdOutlineUnsubscribe, MdWeb } from 'react-icons/md';
import { TbRefreshAlert, TbRefreshDot } from 'react-icons/tb';
import { LuRefreshCwOff } from "react-icons/lu";
import { SiGoogleads, SiGoogleadsense } from "react-icons/si";

export default function Dashboard() {
  const { refreshed } = useContext(Context)
  const navigate = useNavigate()
  const check = usePermission()
  const [counts, setCounts] = useState({
    companies: 0,
    keywords: 0,
    email: 0,
    website: 0,
    user: 0
  })
  const [request, loading] = useAPI({ route: 'dashboard-count' })

  const callAPI = async () => {
    const response = await request()
    if (response) {
      setCounts(response)
    }
  }

  useEffect(() => {
    callAPI()
    // eslint-disable-next-line
  }, [refreshed])

  return (
    <div className='flex flex-col gap-4'>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 m-4 gap-4">
        {[
          {
            title: 'Emails',
            key: 'email',
            icon: HiOutlineMail,
            color: '#2980B9',
            link: '/emails',
            permission: 'viewEmail'
          },
          {
            title: 'Keywords',
            key: 'keywords',
            icon: CiSearch,
            color: '#3F51B5',
            link: '/keyword',
            permission: 'viewKeyword'
          },
          {
            title: 'Websites',
            key: 'website',
            icon: MdWeb,
            color: '#9C27B0',
            link: '/website',
            permission: 'viewWebsite'
          },
          {
            title: 'Users',
            key: 'user',
            icon: FiUser,
            color: '#5B2C6F',
            link: '/users',
            permission: 'viewUser'
          },
        ].map(item => {
          const { title, key, icon, color, link, permission } = item
          const Icon = icon;
          return (check(permission) ? (
            <div key={key} className=' bg-gray-100/50 hover:bg-gray-100 cursor-pointer rounded-lg overflow-hidden shadow-md' onClick={() => navigate(link)}>
              <div style={{ color }} className='flex justify-between items-center p-5'>
                {loading ? < Spin /> : <h2 className='text-4xl'>{Intl.NumberFormat().format(counts[key])}</h2>}
                <div className='p-3 rounded-xl relative overflow-hidden'>
                  <div className="absolute w-full h-full top-0 left-0 opacity-20" style={{ background: color }}></div>
                  <Icon className='text-5xl ' />
                </div>
              </div>
              <h3 className='text-xl text-gray-700 px-5'>{title}</h3>
              <div style={{ backgroundColor: color, }} className={`h-2 w-full mt-2`} />
            </div>
          ) : <></>)
        })}
        <SampleDownload />
        {[
          {
            title: 'Add New',
            icon: MdAdd,
            color: '#2563eb',
            action: 'add',
            permission: 'uploadNewEmail'
          },
          {
            title: 'Unsubscribe',
            icon: MdOutlineUnsubscribe,
            color: '#dc2626',
            action: 'unsubscribe',
            permission: 'allowUnsubscribeEmail'
          },
          {
            title: 'Subscribe',
            icon: MdMarkEmailRead,
            color: '#1E8449',
            action: 'subscribe',
            permission: 'allowUnsubscribeEmail'
          },
          {
            title: 'Soft Bounce',
            icon: TbRefreshAlert,
            color: '#DC7633',
            action: 'soft',
            permission: 'allowSoftBounceEmail'
          },
          {
            title: 'Soft UnBounce',
            icon: LuRefreshCwOff,
            color: '#DC7633',
            action: 'SoftUnBounce',
            permission: 'allowSoftBounceEmail'
          },
          {
            title: 'Hard Bounce',
            icon: TbRefreshDot,
            color: '#E74C3C',
            action: 'hard',
            permission: 'allowHardBounceEmail'
          },
          {
            title: 'Hard UnBounce',
            icon: LuRefreshCwOff,
            color: '#E74C3C',
            action: 'HardUnBounce',
            permission: 'allowHardBounceEmail'
          },
          {
            title: 'Leads',
            icon: SiGoogleads,
            color: '#52c41a',
            action: 'leads',
            permission: 'allowLeadsEmail'
          },
          {
            title: 'Sales',
            icon: IoAnalytics,
            color: '#faad14',
            action: 'sales',
            permission: 'allowSalesEmail'
          },
          {
            title: 'Sales/Leads To Normal',
            icon: SiGoogleadsense,
            color: '#00BCD4',
            action: 'normalSalesLeads',
            permission: 'normalSalesLeads'
          },
        ].map(e => (check(e.permission) ? (
          <UploadAndAction
            key={e.title}
            title={e.title}
            Icon={e.icon}
            color={e.color}
            action={e.action}
          />) : <></>))}
        {check('downloadAllEmail') && <DownloadAll />}
        {check('clearAllDatabase') && <ClearAll />}
      </div>
    </div>
  )
}
