import { useState } from 'react';

export default function useLocalStorage(key, initialValue, obj = false) {

    const [value, setValue] = useState(() => {
        let storedValue = localStorage.getItem(key);
        if (storedValue) {
            if (obj) {
                try {
                    return JSON.parse(storedValue)
                } catch (error) {
                    return initialValue
                }
            }
            else
                return storedValue
        } else {
            return initialValue
        }
    });

    const updateValue = (newValue) => {
        setValue(newValue);
        let strValue = obj ? JSON.stringify(newValue) : newValue;
        localStorage.setItem(key, strValue);
    };

    const removeValue = () => {
        localStorage.removeItem(key);
        setValue(obj ? {} : null);
    };

    return [value, updateValue, removeValue];
}
