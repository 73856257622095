import React from 'react'
import { ReactComponent as ReactLogo } from '../Assets/Error404.svg'
export default function Error404() {
  return (
    <div className="flex w-full h-full justify-center items-center">
      <div className='flex justify-center items-center max-w-xl h-full w-full text-skin-300 text-[#1677ff]'>
        <ReactLogo />
      </div>
    </div>
  )
}
