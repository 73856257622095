import { ExclamationCircleFilled } from '@ant-design/icons'
import { Modal, Spin } from 'antd'
import React, { useState } from 'react'
import { BsDatabaseDown } from 'react-icons/bs'
import { useAPI } from '../Hooks'
import { AiOutlineClear } from 'react-icons/ai'

export default function ClearAll() {
    const [modalOpen, setModalOpen] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const [request] = useAPI({ method: 'DELETE' })

    const action = async () => {
        setShowConfirm(false)
        setModalOpen(true)
        await request('clear-all')
        setModalOpen(false)
    }

    return (
        <>
            <Modal
                title={
                    <div className='flex items-center text-xl'>
                        <BsDatabaseDown className='text-[#262626] mr-3' />
                        Download All Database
                    </div>}
                open={modalOpen}
                centered
                closeIcon={false}
                footer={false}
            >
                <div className="flex items-center gap-2 flex-col mt-6">
                    <Spin size="large" />
                    <h1 className='text-xl'>Crating File</h1>
                </div>
            </Modal>
            <Modal
                title={
                    <div className='flex items-center text-xl'>
                        <ExclamationCircleFilled className='text-orange-600 mr-3' />
                        Are you sure ?
                    </div>}
                open={showConfirm}
                centered
                okText='Delete All'
                okButtonProps={{ danger: true }}
                onOk={action}
                onCancel={() => setShowConfirm(false)}
            />
            <div
                onClick={() => setShowConfirm(true)}
                className='bg-gray-100/50 hover:bg-gray-100 cursor-pointer !rounded-lg overflow-hidden flex flex-col items-center shadow-md '>
                <div className={`bg-red-500/20 text-red-500 p-3 rounded-xl m-5`}>
                    <AiOutlineClear className='text-5xl' />
                </div>
                <h3 className='text-xl text-gray-700 px-5'>Clear All Database</h3>
                <div className={`bg-red-500 h-2 w-full mt-2`} />
            </div>
        </>
    )
}
