import { Button, FloatButton, Input, Modal, Pagination, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAPI, usePermission, useSessionStorage } from '../Hooks';
import dayjs from 'dayjs';
import { AiOutlineDelete } from 'react-icons/ai';
import { GrCloudComputer } from "react-icons/gr";

export default function AllowIP() {
  const check = usePermission()
  const [pageSize, setPageSize] = useSessionStorage('pageSize', 10)
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [Data, setData] = useState([])
  const [openAddNew, setOpenAddNew] = useState(false)
  const [newIP, setNewIP] = useState('')

  const [AddIPRequest, WorkingAddIP] = useAPI({
    route: 'allow-ip',
    method: 'POST',
    body: {
      ip: newIP
    }
  })

  const AddIP = async () => {
    const data = await AddIPRequest()
    if (data) {
      setOpenAddNew(false)
      setNewIP('')
      getData()
    }
  }

  const [loadDomain, loading] = useAPI({})
  const getData = async () => {
    const data = await loadDomain(`allow-ip?limit=${pageSize}&page=${pageNo}`)
    if (data) {
      setTotal(data.total)
      setData(data.data)
    }
  }
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [pageNo, pageSize])

  const [deleteRequest, deleting] = useAPI({
    method: 'DELETE'
  })

  const Delete = async (domain) => {
    const data = await deleteRequest(`allow-ip/${domain}`)
    if (data) {
      getData()
    }
  }

  return (
    <>
      {check('addNewIP') && <FloatButton
        type='primary'
        tooltip='Add allow IP'
        icon={<GrCloudComputer />}
        onClick={() => setOpenAddNew(true)}
      />}
      <Modal
        title={
          <div className='flex items-center text-xl'>
            <GrCloudComputer className='text-orange-600 mr-3' />
            Add Allow IP
          </div>}
        open={openAddNew}
        centered
        closable={!WorkingAddIP}
        okText='Add'
        onCancel={() => setOpenAddNew(false)}
        onOk={AddIP}
      >
        <Input
          addonBefore={<label htmlFor='allowIP' className='w-20 block text-start'>IP</label>}
          id='allowIP'
          name='allowIP'
          value={newIP}
          onChange={({ target }) => setNewIP(target.value)}
          size='large'
        />
      </Modal>
      <Table
        loading={loading}
        className='min-w-[850px]'
        columns={[
          {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
          },
          {
            title: 'Add By',
            dataIndex: 'by',
            key: 'by',
            align: 'center',
          },
          {
            title: 'Add At',
            key: 'at',
            align: 'center',
            render: (_, record) => dayjs(record.createdAt).format('DD MMM YYYY - h:m')
          },
          {
            title: 'Action',
            key: 'action',
            hidden: !check('deleteAllowIP'),
            align: 'center',
            render: (_, record) => (
              <div className='flex justify-center'>
                {check('deleteAllowIP') && <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => { Delete(record.ip) }}
                >
                  <Button
                    disabled={deleting}
                    danger
                    className='flex gap-2 items-center'>
                    <AiOutlineDelete />
                    Delete
                  </Button>
                </Popconfirm>}
              </div>
            )
          }
        ]}
        sticky
        dataSource={Data}
        pagination={false}
      />
      <div className="my-5 w-full flex justify-end px-4">
        <Pagination
          responsive
          current={pageNo}
          pageSize={pageSize}
          total={total}
          onChange={(page, pageSize) => { setPageNo(page); setPageSize(pageSize) }}
        />
      </div>
    </>
  )
}
