import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, DatePicker, InputNumber, Pagination, Space, Table } from 'antd';
import { useAPI, usePermission, useSessionStorage } from '../Hooks';
import dayjs from 'dayjs';
import { GrPowerReset } from 'react-icons/gr';
import presets from '../Utils/DatePresets';

export default function Keyword() {
    const check = usePermission()
    const [pageSize, setPageSize] = useSessionStorage('pageSize', 10)
    const [pageNo, setPageNo] = useState(1)
    const [total, setTotal] = useState(0)
    const [Data, setData] = useState([])
    const [search, setSearch] = useState({
        fromDate: null,
        toDate: null,
        downloadCount: null,
        sorter: {
            field: "",
            order: ""
        },
    })

    const [request, loading] = useAPI({
        route: `keyword?limit=${pageSize}&page=${pageNo}`,
        method: 'POST',
        body: {
            ...search,
            toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
            fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
        },
    })
    const [deleteRequest] = useAPI({
        route: `keyword`,
        method: 'DELETE',
        body: {
            ...search,
            toDate: (search.toDate) ? (dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
            fromDate: (search.fromDate) ? (dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
        },
    })

    const deleteButton = async (id = null) => {
        await deleteRequest()
        getData()
    }

    const getData = async () => {
        let data = await request()
        if (data) {
            setTotal(data.total)
            setData(data.data)
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [pageNo, pageSize, search])

    const selectDate = (name, date) => {
        if (date) {
            setSearch({
                ...search,
                [name]: dayjs(date)
            })
        } else {
            setSearch({
                ...search,
                [name]: null
            })
        }
    }

    const tableColumn = [
        {
            title: 'Keyword',
            dataIndex: 'name',
            key: 'name',
            sortOrder: (search.sorter.field === 'name') ? search.sorter.order : null,
            sorter: true
        },
        {
            title: 'Download Count',
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            sortOrder: (search.sorter.field === 'count') ? search.sorter.order : null,
            sorter: true
        },
        {
            title: 'Last Download',
            key: 'last',
            dataIndex: 'last',
            align: 'center',
            render: (_, record) => record.last ? dayjs(record.last).format('DD MMM YYYY - hh:mm A') : 'Not Download',
            sortOrder: (search.sorter.field === 'last') ? search.sorter.order : null,
            sorter: true
        },
    ]

    return <>
        <div className="p-4 flex flex-col gap-2">
            <Space.Compact className="bg-gray-100 items-center rounded-lg flex">
                <h5 className=' px-3 shrink-0'>Date Range</h5>
                <DatePicker
                    value={search.fromDate && dayjs(search.fromDate)}
                    size='large'
                    className='w-full'
                    presets={presets}
                    placeholder='from'
                    onChange={(e) => selectDate('fromDate', e)}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                />
                <DatePicker
                    value={search.toDate && dayjs(search.toDate)}
                    size='large'
                    className='w-full'
                    presets={presets}
                    placeholder='to'
                    onChange={(e) => selectDate('toDate', e)}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                />
            </Space.Compact>
            <Space.Compact className="bg-gray-100 items-center rounded-lg flex">
                <h5 className=' px-3 shrink-0'>Download Count</h5>
                <InputNumber
                    className='w-full'
                    value={search.downloadCount}
                    min="0"
                    max="10000"
                    step="1"
                    size='large'
                    onChange={(input) => setSearch({ ...search, downloadCount: input })}
                />
                {check('resetKeyword') && <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => deleteButton()}
                >
                    <Button
                        danger
                        type='primary'
                        size='large'
                        className='flex gap-2 items-center !px-[40px]'>
                        <GrPowerReset />
                        Reset
                    </Button>
                </Popconfirm>}
            </Space.Compact>
        </div>
        <Table
            loading={loading}
            className='min-w-[850px]'
            columns={tableColumn}
            sticky
            dataSource={Data}
            pagination={false}
            onChange={(p, f, { field, order }) => {
                setSearch({
                    ...search,
                    sorter: { field, order }
                })
            }}
        />
        <div className="my-5 w-full flex justify-end px-4">
            <Pagination
                responsive
                current={pageNo}
                pageSize={pageSize}
                total={total}
                onChange={(page, pageSize) => { setPageNo(page); setPageSize(pageSize) }}
            />
        </div>
    </>
}