import { Button, InputNumber, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAPI } from '../Hooks'

export default function CoolingPeriod() {
    const [coolingPeriod, setCoolingPeriod] = useState(0)

    const [GetData, loading] = useAPI({})

    useEffect(() => {
        GetData('setting/cooling-period').then(({ value }) => {
            if (value)
                setCoolingPeriod(value.cp)
        })
        // eslint-disable-next-line
    }, [])

    const [save, saving] = useAPI({
        method: 'POST',
        route: 'setting/cooling-period',
        body: {
            value: { cp: coolingPeriod }
        }
    })

    return (
        <div className='p-3'>
            <Space.Compact className="bg-gray-100 flex items-center rounded-md">
                <h5 className=' px-3 shrink-0'>Cooling Period</h5>
                <InputNumber
                    disabled={loading || saving}
                    className='w-full'
                    value={coolingPeriod}
                    min="0"
                    max="1000"
                    step="1"
                    size='large'
                    onChange={(input) => { setCoolingPeriod(input) }}
                />
            </Space.Compact>
            <div className="flex justify-end p-4">
                <Button type='primary' size='large' disabled={loading || saving} onClick={() => save()}>
                    Save
                </Button>
            </div>
        </div>
    )
}
