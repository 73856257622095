import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Pagination, Popconfirm, Space, Table } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAPI, usePermission, useSessionStorage } from '../Hooks';
import { AiOutlineDelete } from 'react-icons/ai';
import { CiLogout } from 'react-icons/ci';
import presets from '../Utils/DatePresets';
dayjs.extend(relativeTime)

export default function UserLog() {
    const check = usePermission()
    const [pageSize, setPageSize] = useSessionStorage('pageSize', 10)
    const [pageNo, setPageNo] = useState(1)
    const [total, setTotal] = useState(0)
    const [Data, setData] = useState([])
    const [search, setSearch] = useState({
        fromDate: null,
        toDate: null,
    })

    const [request, loading] = useAPI({
        route: `user-log?limit=${pageSize}&page=${pageNo}`,
        method: 'POST',
        body: {
            all: check('allUserLog'),
            toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
            fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
        },
    })

    const getData = async () => {
        let data = await request()
        if (data) {
            setTotal(data.total)
            setData(data.data)
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [pageNo, pageSize, search])

    const selectDate = (name, date) => {
        if (date) {
            setSearch({
                ...search,
                [name]: dayjs(date)
            })
        } else {
            setSearch({
                ...search,
                [name]: null
            })
        }
    }

    const [deleteRequest, deleting] = useAPI({
        route: `user-log`,
        method: 'DELETE',
        body: {
            all: check('allUserLog'),
            toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
            fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
        },
    })

    const deleteButton = async (id = null) => {
        if (id)
            await deleteRequest(`user-log/${id}`)
        else
            await deleteRequest()
        getData()
    }

    return <>
        <div className="p-4 flex flex-col gap-2">
            <Space.Compact className="bg-gray-100 items-center rounded-lg flex">
                <h5 className=' px-3 shrink-0'>Date Range</h5>
                <DatePicker
                    disabled={deleting}
                    value={search.fromDate && dayjs(search.fromDate)}
                    size='large'
                    className='w-full'
                    presets={presets}
                    placeholder='from'
                    onChange={(e) => selectDate('fromDate', e)}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                />
                <DatePicker
                    disabled={deleting}
                    value={search.toDate && dayjs(search.toDate)}
                    size='large'
                    className='w-full'
                    presets={presets}
                    placeholder='to'
                    onChange={(e) => selectDate('toDate', e)}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                />
                {check('deleteUserLog') && <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => deleteButton()}
                >
                    <Button
                        danger
                        type='primary'
                        size='large'
                        disabled={!(search.fromDate || search.toDate)}
                        className='flex gap-2 items-center'>
                        <AiOutlineDelete />
                        Delete
                    </Button>
                </Popconfirm>}
            </Space.Compact>
        </div>
        <Table
            loading={loading}
            columns={[
                {
                    title: 'Username',
                    key: 'createdAt',
                    align: 'center',
                    // hidden: !(check('allUserLog')),
                    render: (_, record) => (record?.user?.username)
                },
                {
                    title: 'Login At',
                    key: 'createdAt',
                    align: 'center',
                    render: (_, record) => dayjs(record.createdAt).format('DD MMM YYYY - hh:mm A')
                },
                {
                    title: 'Ago',
                    key: 'createdAt',
                    align: 'center',
                    render: (_, record) => dayjs().to(dayjs(record.createdAt))
                },
                {
                    title: 'Action',
                    key: 'action',
                    hidden: !(check('logoutOtherUser')),
                    align: 'center',
                    render: (_, record) => (
                        <div className='flex justify-center gap-2'>
                            <Popconfirm title="Sure to Logout?" onConfirm={() => deleteButton(record._id)}>
                                <Button disabled={record.logout} danger className='flex gap-2 items-center'>
                                    <CiLogout />
                                </Button>
                            </Popconfirm>
                        </div>
                    )
                }
            ]}
            sticky
            dataSource={Data}
            pagination={false}
        />
        <div className="my-5 w-full flex justify-end px-4">
            <Pagination
                responsive
                current={pageNo}
                pageSize={pageSize}
                total={total}
                onChange={(page, pageSize) => { setPageNo(page); setPageSize(pageSize) }}
            />
        </div>
    </>
}