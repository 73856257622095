import React, { useContext } from 'react'
import { Button, Statistic, Typography } from 'antd';
import { AiOutlineClear } from "react-icons/ai";
import DownloadBox from './DownloadBox';
import { usePermission } from '../Hooks';
import Context from '../Utils/Context';
import DeleteBox from './DeleteBox';
const { Title } = Typography;

export default function ActionBox({ total }) {
    const { RemoveSearch } = useContext(Context)
    const check = usePermission()
    return (
        <div className='flex-grow basis-[300px] flex flex-col gap-4 items-center '>
            <Title className='text-center !m-0' level={3}>Searched mail</Title>
            <Statistic value={total} className='font-bold' />
            {check('downloadEmail') && (<DownloadBox disabled={total <= 0} />)}
            {check('deleteEmail') && (<DeleteBox disabled={total <= 0} />)}
            <Button
                size='large'
                onClick={() => RemoveSearch()}
                danger
                className='flex gap-2 w-48 justify-center items-center'
            >
                <AiOutlineClear />
                Reset Search
            </Button>
        </div>
    )
}
