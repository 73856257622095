import React, { useContext, useState } from "react";
import { Button, Modal, Spin, Statistic } from "antd";
import { IoCloudDownloadOutline } from "react-icons/io5";
import Context from "../Utils/Context";
import dayjs from "dayjs";
import handleDownload from "../Utils/handleDownload";
import { BsDatabaseDown } from "react-icons/bs";
import useStream from "../Hooks/useStream";

export default function DownloadBox({ disabled }) {
  const { search, SERVER, creditRefresh, Message } = useContext(Context);
  const [modalOpen, setModalOpen] = useState(false);

  const { request, data, cancelNew } = useStream(
    (action) => {
      setModalOpen(false);
      Message.open({
        key: "download",
        type: action,
        content:
          "Create file" +
          (action === "success"
            ? " Successfully"
            : action === "warning"
            ? " Cancel"
            : " Failed"),
      });
      creditRefresh();
    },
    (fileName) => {
      handleDownload(fileName, SERVER);
    }
  );

  const Download = async () => {
    setModalOpen(true);
    request("new-download", {
      ...search,
      toDate: search.toDate
        ? new Date(dayjs(search.toDate).add(1, "day").format("YYYY/MM/DD"))
        : null,
      fromDate: search.fromDate
        ? new Date(dayjs(search.fromDate).add(0, "day").format("YYYY/MM/DD"))
        : null,
    });
  };

  return (
    <>
      <Modal
        title={
          <div className="flex items-center text-xl">
            <BsDatabaseDown className="text-[#262626] mr-3" />
            Download Emails
          </div>
        }
        open={modalOpen}
        centered
        closeIcon={false}
        footer={false}
      >
        <div className="flex items-center gap-2 flex-col mt-6">
          <div className="flex items-center gap-3">
            <Spin />
            <Statistic value={data} suffix="%" />
          </div>
          <h1 className="text-gray-600">Create CSV File</h1>
          <Button type="primary" danger key="Cancel" onClick={cancelNew}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Button
        disabled={disabled}
        onClick={() => Download()}
        size="large"
        type="primary"
        className="flex gap-2 w-48 justify-center items-center"
      >
        <IoCloudDownloadOutline />
        Download
      </Button>
    </>
  );
}
