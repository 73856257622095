import React, { useContext, useEffect, useState } from 'react';
import { Button, DatePicker, Pagination, Popconfirm, Space, Table } from 'antd';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAPI, usePermission, useSessionStorage } from '../Hooks';
import Context from '../Utils/Context';
import { AiOutlineDelete } from 'react-icons/ai';
import presets from '../Utils/DatePresets';
dayjs.extend(relativeTime)

export default function Download() {
    const check = usePermission()
    const { SERVER } = useContext(Context)
    const [pageSize, setPageSize] = useSessionStorage('pageSize', 10)
    const [pageNo, setPageNo] = useState(1)
    const [total, setTotal] = useState(0)
    const [Data, setData] = useState([])
    const [search, setSearch] = useState({
        fromDate: null,
        toDate: null,
    })

    const [request, loading] = useAPI({
        route: `download?limit=${pageSize}&page=${pageNo}`,
        method: 'POST',
        body: {
            all: check('viewAllDownload'),
            toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
            fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
        },
    })

    const getData = async () => {
        let data = await request()
        if (data) {
            setTotal(data.total)
            setData(data.data)
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [pageNo, pageSize, search])

    const [againDownload, againLoading] = useAPI({})

    const DownloadFile = (file, id) => {
        const link = document.createElement('a');
        link.href = `${SERVER}/file/download/${file}`;
        link.download = file;
        link.target = '_blank';
        link.click();
        againDownload(`download/${id}`).then(d => {
            getData()
        })
    }

    const selectDate = (name, date) => {
        if (date) {
            setSearch({
                ...search,
                [name]: dayjs(date)
            })
        } else {
            setSearch({
                ...search,
                [name]: null
            })
        }
    }

    const [deleteRequest, deleting] = useAPI({
        route: `download`,
        method: 'DELETE',
        body: {
            all: check('viewAllDownload'),
            toDate: (search.toDate) ? new Date(dayjs(search.toDate).add(1, 'day').format('YYYY/MM/DD')) : null,
            fromDate: (search.fromDate) ? new Date(dayjs(search.fromDate).add(0, 'day').format('YYYY/MM/DD')) : null,
        },
    })

    const deleteButton = async (id = null) => {
        if (id)
            await deleteRequest(`download/${id}`)
        else
            await deleteRequest()
        getData()
    }

    return <>
        <div className="p-4 flex flex-col gap-2">
            <Space.Compact className="bg-gray-100 items-center rounded-lg flex">
                <h5 className=' px-3 shrink-0'>Date Range</h5>
                <DatePicker
                    disabled={deleting}
                    value={search.fromDate && dayjs(search.fromDate)}
                    size='large'
                    className='w-full'
                    presets={presets}
                    placeholder='from'
                    onChange={(e) => selectDate('fromDate', e)}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                />
                <DatePicker
                    disabled={deleting}
                    value={search.toDate && dayjs(search.toDate)}
                    size='large'
                    className='w-full'
                    presets={presets}
                    placeholder='to'
                    onChange={(e) => selectDate('toDate', e)}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                />
                {check('deleteDownload') && <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => deleteButton()}
                >
                    <Button
                        danger
                        type='primary'
                        size='large'
                        disabled={!(search.fromDate || search.toDate)}
                        className='flex gap-2 items-center'>
                        <AiOutlineDelete />
                        Delete
                    </Button>
                </Popconfirm>}
            </Space.Compact>
        </div>
        <Table
            loading={loading || againLoading}
            className='min-w-[850px]'
            columns={[
                {
                    title: 'Create By',
                    dataIndex: 'by',
                    key: 'by',
                    hidden: !(check('viewAllDownload')),
                    align: 'center',
                },
                {
                    title: 'Create At',
                    key: 'at',
                    align: 'center',
                    render: (_, record) => dayjs(record.createdAt).format('DD MMM YYYY - hh:mm A')
                },
                {
                    title: 'Mails',
                    align: 'center',
                    key: 'mails',
                    render: (_, record) => Intl.NumberFormat().format(record.mails)
                },
                {
                    title: 'Download Count',
                    dataIndex: 'count',
                    key: 'count',
                    align: 'center',
                },
                {
                    title: 'Last Download Date',
                    dataIndex: 'time',
                    key: 'time',
                    align: 'center',
                    render: (_, record) => dayjs(record.time).format('DD MMM YYYY - hh:mm A')
                },
                {
                    title: 'Last Download',
                    key: 'time',
                    align: 'center',
                    render: (_, record) => dayjs().to(dayjs(record.time))
                },
                {
                    title: 'Action',
                    key: 'action',
                    hidden: !(check('downloadEmail') || check('deleteDownload')),
                    align: 'center',
                    render: (_, record) => (
                        <div className='flex justify-center gap-2'>
                            {check('downloadEmail') && <Button type='primary' className='inline-flex gap-2 items-center' onClick={() => DownloadFile(record.file, record._id)}>
                                <IoCloudDownloadOutline />
                            </Button>}
                            {check('deleteDownload') && <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => deleteButton(record._id)}
                            >
                                <Button
                                    danger
                                    className='flex gap-2 items-center'>
                                    <AiOutlineDelete />
                                </Button>
                            </Popconfirm>}
                        </div>
                    )
                }
            ]}
            sticky
            dataSource={Data}
            pagination={false}
        />
        <div className="my-5 w-full flex justify-end px-4">
            <Pagination
                responsive
                current={pageNo}
                pageSize={pageSize}
                total={total}
                onChange={(page, pageSize) => { setPageNo(page); setPageSize(pageSize) }}
            />
        </div>
    </>
}