import { useContext, useState } from 'react';
import Context from '../Utils/Context';
import { useNavigate } from 'react-router-dom';
const CustomError = (code) => [409, 401, 406, 401, 404, 422].includes(code)

export default function useAPI({ route = '', method = 'GET', body = null, auth = true, showBar = true }) {
    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false)
    const { token, setProgress, SERVER, Message } = useContext(Context)
    const BASEURL = SERVER + '/api'

    const request = async (newRoute = null, newBody = null) => {
        try {
            setLoading(true)
            if (showBar)
                setProgress(10)
            const requestBody = {
                method,
                headers: {
                    "Content-Type": "application/json",
                }
            }
            if (auth) {
                if (!token) {
                    navigate('/login')
                    throw new Error("Please Login")
                }

                let response = await fetch(`${BASEURL}/refresh`, {
                    method: "POST",
                    body: JSON.stringify({ token }),
                    headers: requestBody.headers
                })
                if (showBar)
                    setProgress(30)
                if (response.status === 200) {
                    let data = await response.json();
                    requestBody.headers.authorization = `Basic ${data.token}`
                } else {
                    navigate('/login')
                    throw new Error("Login Again")
                }
            }
            if (body)
                requestBody.body = JSON.stringify(body);
            
            if (newBody)
                requestBody.body = JSON.stringify(newBody);

            if (showBar)
                setProgress(40)

            let response = await fetch(`${BASEURL}/${newRoute || route}`, requestBody);

            if (showBar)
                setProgress(80)

            if (response.status === 200) {
                return await response.json();
            } else if (CustomError(response.status)) {
                let data = await response.json();
                throw new Error(data.message);
            } else {
                throw new Error("Server Error");
            }
        } catch (error) {
            Message.open({
                key: "server-error",
                type: 'error',
                content: error.message,
                duration: 3,
            });
            return null
        } finally {
            if (showBar)
                setProgress(100)
            setLoading(false)
        }
    }

    return [request, Loading];
}
