const Permission = [
    {
        title: 'Allow All IP',
        key: 'allowAllIP',
    },
    {
        title: 'Upload New Email',
        key: 'uploadNewEmail',
    },
    {
        title: 'Download & View All Email',
        key: 'downloadAllEmail',
    },
    {
        title: 'Clear All Database',
        key: 'clearAllDatabase',
    },
    {
        title: 'View Email',
        key: 'viewEmail',
    },
    {
        title: 'Download Email',
        key: 'downloadEmail',
    },
    {
        title: 'Download Email with Campaign',
        key: 'downloadEmailWithCampaign',
    },
    {
        title: 'Delete Email',
        key: 'deleteEmail',
    },
    {
        title: 'Allow View Normal Email',
        key: 'allowViewNormalEmail',
    },
    {
        title: 'Apply date range on Email',
        key: 'ApplyDateRange',
    },
    {
        title: 'Normal Clickable',
        key: 'NormalClickable',
    },
    {
        title: 'Allow Unsubscribe Email',
        key: 'allowUnsubscribeEmail',
    },
    {
        title: 'Allow Soft Bounce Email',
        key: 'allowSoftBounceEmail',
    },
    {
        title: 'Allow Hard Bounce Email',
        key: 'allowHardBounceEmail',
    },
    {
        title: 'Allow Leads Email',
        key: 'allowLeadsEmail',
    },
    {
        title: 'Allow Sales Email',
        key: 'allowSalesEmail',
    },
    {
        title: 'Sales/Leads to Normal Email',
        key: 'normalSalesLeads',
    },
    {
        title: 'View Website',
        key: 'viewWebsite',
    },
    {
        title: 'Reset Website',
        key: 'resetWebsite',
    },
    {
        title: 'View Keyword',
        key: 'viewKeyword',
    },
    {
        title: 'Reset Keyword',
        key: 'resetKeyword',
    },
    // for Download Section
    {
        title: 'View Download',
        key: 'viewDownload',
    },
    {
        title: 'View All Download',
        key: 'viewAllDownload',
    },
    {
        title: 'Delete Download',
        key: 'deleteDownload',
    },
    // for Cooling Period Section
    {
        title: 'View Cooling Period Email',
        key: 'viewCoolingPeriodEmail',
    },
    // for Countries Section
    {
        title: 'View Countries',
        key: 'viewCountries',
    },
    // for Department Section
    {
        title: 'View Campaigns',
        key: 'viewCampaigns',
    },
    // for User Section
    {
        title: 'View User',
        key: 'viewUser',
    },
    {
        title: 'Create User',
        key: 'createUser',
    },
    {
        title: 'Edit User',
        key: 'editUser',
    },
    {
        title: 'Delete User',
        key: 'deleteUser',
    },
    {
        title: 'View User Log',
        key: 'viewUserLog',
    },
    {
        title: 'All User Log',
        key: 'allUserLog',
    },
    {
        title: 'Delete User Log',
        key: 'deleteUserLog',
    },
    {
        title: 'Logout Other User',
        key: 'logoutOtherUser',
    },
    // for Domains Section
    {
        title: 'View Block Domains',
        key: 'viewBlockDomains',
    },
    {
        title: 'Add Block Domains',
        key: 'addBlockDomains',
    },
    {
        title: 'Delete Block Domains',
        key: 'deleteBlockDomains',
    },
    // for IP Section
    {
        title: 'View Allow IP',
        key: 'viewIP',
    },
    {
        title: 'Add New IP',
        key: 'addNewIP',
    },
    {
        title: 'Delete Allow IP',
        key: 'deleteAllowIP',
    },
]

export default Permission;