import React, { useEffect } from 'react'

export default function DevLicense() {
    const license = `
    <a>Project ID : 2401 </a>
    <a>Developer Name : M Anas Latif </a>
    <a>website : https://m.anaslatif.com </a>
    <a>github : https://github.com/MAnasLatif </a>
    `

    useEffect(() => {
        const Box = document.createElement('DevLicense');
        Box.style.display = 'none';
        Box.innerHTML = license
        document.body.appendChild(Box);
        return () => {
            document.body.removeChild(Box);
        };
        // eslint-disable-next-line
    }, []);
    return <></>
}
