import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Input, Modal, Statistic } from 'antd'
import React, { useState } from 'react'
import { MdOutlineAttachEmail, MdPassword } from 'react-icons/md'
import { TbPasswordUser } from "react-icons/tb";
const { Countdown } = Statistic;

export default function ForgotPassword({ OpenForgotPassword, setOpenForgotPassword }) {
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState({
        NewPassword: '',
        ConfirmPassword: ''
    })

    const addPass = ({ target }) => {
        let { name, value } = target
        setPassword({
            ...password,
            [name]: value
        })
    }

    const send = () => {
        setOpenForgotPassword('code')
    }

    const verify = () => {
        setOpenForgotPassword('NewPass')
    }

    const NewPassword = () => {
        setOpenForgotPassword('')
    }

    return (
        <>
            <Modal
                title={
                    <div className='flex items-center text-xl'>
                        <MdOutlineAttachEmail className='text-orange-600 mr-3' />
                        Forgot Password
                    </div>}
                open={OpenForgotPassword === 'email'}
                centered
                okText='Send'
                onCancel={() => setOpenForgotPassword('')}
                onOk={send}
            >
                <Input
                    addonBefore={<label htmlFor='Email' className='w-10 block text-start'>Email</label>}
                    id='Email'
                    name='email'
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    size='large'
                />
            </Modal>
            <Modal
                title={
                    <div className='flex items-center justify-between text-xl'>
                        <div className='flex items-center text-xl'>
                            <MdPassword className='text-orange-600 mr-3' />
                            Verify
                        </div>
                        <Countdown
                            format='mm:ss'
                            value={Date.now() + 1000 * 60}
                            onFinish={() => setOpenForgotPassword('')}
                        />
                    </div>}
                open={OpenForgotPassword === 'code'}
                centered
                okText='verify'
                onCancel={() => setOpenForgotPassword('')}
                closeIcon={false}
                onOk={verify}
            >
                <Input
                    addonBefore={<label htmlFor='code' className='w-10 block text-start'>Code</label>}
                    id='code'
                    name='code'
                    value={code}
                    onChange={({ target }) => setCode(target.value)}
                    size='large'
                />
            </Modal>
            <Modal
                title={
                    <div className='flex items-center text-xl'>
                        <TbPasswordUser className='text-orange-600 mr-3' />
                        New Password
                    </div>}
                open={OpenForgotPassword === 'NewPass'}
                centered
                okText='save'
                onCancel={() => setOpenForgotPassword('')}
                closeIcon={false}
                onOk={NewPassword}
            >
                <Input.Password
                    addonBefore={<label htmlFor='NewPassword' className='w-32 block text-start '>New Password</label>}
                    className='my-5'
                    id='NewPassword'
                    name='NewPassword'
                    value={password.NewPassword}
                    onChange={addPass}
                    size='large'
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
                <Input.Password
                    addonBefore={<label htmlFor='ConfirmPassword' className='w-32 block text-start'>Confirm Password</label>}
                    id='ConfirmPassword'
                    name='ConfirmPassword'
                    value={password.ConfirmPassword}
                    onChange={addPass}
                    size='large'
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Modal>
        </>
    )
}
