import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { HiOutlineMail } from "react-icons/hi";
import { CiFlag1, CiLogout } from "react-icons/ci";
import { MdBlock, MdOutlineCampaign, MdOutlineDashboard, MdOutlineSwipeRight } from "react-icons/md";
import { LiaUserClockSolid } from "react-icons/lia";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, } from '@ant-design/icons';
import { Layout, Menu, Button, Dropdown, Avatar, Typography } from 'antd';
import Context from '../Utils/Context';
import UserInfo from '../Utils/UserInfo';
import { useAPI, usePermission } from '../Hooks';
import { GrCloudComputer } from 'react-icons/gr';
import UserCredit from './UserCredit';
const { Sider, Content } = Layout;
const { Title } = Typography;

export default function LayoutFun() {
    const { user, token, removeToken, removeUser } = useContext(Context)
    const [mobile, setMobile] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    let location = useLocation();
    const [slug, setSlug] = useState(location.pathname);

    const check = usePermission()

    useEffect(() => {
        setCollapsed(mobile)
    }, [mobile])

    useEffect(() => {
        setSlug(location.pathname)
    }, [location])

    const [LogoutRequest] = useAPI({
        route: 'logout',
        method: 'POST',
        body: { token },
    })

    const logout = () => {
        LogoutRequest()
        removeUser()
        removeToken()
        navigate('/login')
    }
    return (
        <div className='h-screen w-full relative' >
            <header className='bg-gray-100 px-4 py-2 relative z-10 flex items-center justify-between' >
                <div className="flex gap-2 items-center">
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        className='text-xl !w-10 !h-10'
                    />
                    <Title className='!text-xl !m-0 flex gap-3 items-center'>
                        <img className='h-10' src="./img/logo.png" alt="Global B2B Emails" />
                    </Title>
                </div>
                <UserInfo />
                <div className='flex justify-center items-center gap-4'>
                    <UserCredit />
                    <div className="">
                        <Dropdown
                            dropdownRender={() => (
                                <div className='flex flex-col gap-3 p-3 bg-gray-100 shadow-lg rounded-lg border-[1px] border-[#d9d9d9]'>
                                    <div className="">
                                        <h2 className='text-xl text-center '>{user.name || 'Name'}</h2>
                                        <h3 className='text-lg text-center text-gray-600'>{user.username || 'username'}</h3>
                                    </div>
                                    <Button
                                        size='large'
                                        type='primary'
                                        danger
                                        className='flex gap-1 items-center justify-center'
                                        onClick={logout}
                                    >
                                        <CiLogout /> Logout
                                    </Button>
                                </div>
                            )}
                            placement="bottomRight">
                            <Avatar
                                className='cursor-pointer'
                            >{(user.name && user.name.length > 0) ? user.name[0] : 'A'}</Avatar>
                        </Dropdown>
                    </div>
                </div>
            </header>
            <div className="w-full flex h-[calc(100%-64px)] relative">
                {(mobile && !collapsed) && <div className="fixed top-14 left-0 h-full w-screen bg-black/50 z-10" onClick={() => setCollapsed(true)} />}
                <Sider
                    trigger={null}
                    collapsible
                    breakpoint="sm"
                    collapsed={collapsed}
                    onBreakpoint={(broken) => {
                        setMobile(broken);
                    }}
                    className={`!bg-gray-100 h-full z-50 ${mobile && collapsed ? 'hidden' : 'block'} ${mobile ? '!absolute top-0 left-0 shadow-md' : '!relative'}`}
                >
                    <Menu
                        className='!bg-gray-100 !border-0'
                        onClick={(item) => {
                            navigate(item.key)
                            if (mobile) {
                                setCollapsed(true)
                            }
                        }}
                        selectedKeys={slug}
                        items={[
                            {
                                key: '/',
                                icon: <MdOutlineDashboard />,
                                label: 'Dashboard',
                            },
                            {
                                key: '/emails',
                                icon: <HiOutlineMail />,
                                label: 'Emails',
                                disabled: !check('viewEmail')
                            },
                            {
                                key: '/downloads',
                                icon: <IoCloudDownloadOutline />,
                                label: 'Downloads',
                                disabled: !check('viewDownload')
                            },
                            {
                                key: '/campaigns',
                                icon: <MdOutlineCampaign />,
                                label: 'Campaigns',
                                disabled: !check('viewCampaigns')
                            },
                            {
                                key: '/countries',
                                icon: <CiFlag1 />,
                                label: 'Countries',
                                disabled: !check('viewCountries')
                            },
                            {
                                key: '/block-domains',
                                icon: <MdBlock />,
                                label: 'Block Domains',
                                disabled: !check('viewBlockDomains')
                            },
                            {
                                key: '/allow-ip',
                                icon: <GrCloudComputer />,
                                label: 'Allow IP',
                                disabled: !check('viewIP')
                            },
                            {
                                key: '/users',
                                icon: <UserOutlined />,
                                label: 'Users',
                                disabled: !check('viewUser')
                            },
                            {
                                key: '/user-log',
                                icon: <LiaUserClockSolid />,
                                label: 'User Log',
                                disabled: !check('viewUserLog')
                            },
                            {
                                key: '/cooling-period',
                                icon: <MdOutlineSwipeRight />,
                                label: 'Cooling Period',
                                disabled: !check('viewCoolingPeriodEmail')
                            },
                        ]}
                    />
                </Sider>
                <Content className={`${!mobile && 'mx-4 rounded-lg'} bg-white overflow-auto h-[calc(100vh-64px)] relative shadow-lg`}>
                    <Outlet />
                </Content>
            </div>
        </div>
    );
}